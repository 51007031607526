<template>
    <div data-app>
      <div class="page-title pageheading" style="height: 50px">
        <h3 class="titlename">
          <i class="menu-icon flaticon-clock-2"></i
          ><span class="ml-3 text-color">Regional Center Invoicing</span>
        </h3>
        <span class="float-right green-btn">
          <a class="btn btn-success mr-3" @click="payroll">
            <i class="material-icons">add</i> <span>Create</span></a
          >
        </span>
      </div>
      <b-modal
        size="md"
        left
        text
        centered
        id="filter"
        hide-footer
        no-close-on-backdrop
      >
        <template slot="modal-title"
          ><span class="text-color">Regional Center Invoicing</span>
        </template>
        <RegionalcenterReport @screenOneData="openNext"></RegionalcenterReport>
      </b-modal>
    </div>
  </template>
  <script>
  import RegionalcenterReport from "../../components/reprt/RegionalcenterReport.vue";
  import { mapGetters } from "vuex";
  export default {
    components: { RegionalcenterReport },
  
    data() {
      return {
        form: {},
        fields: [
          {
            label: "First Name",
            key: "employeeFirstName",
          },
          {
            label: "Last Name",
            key: "employeeLastName",
          },
          {
            label: "Payer",
            key: "companyName",
          },
          {
            label: "Status",
            key: "payerIsActive",
          },
          {
            label: "Start Time",
            key: "startTimeDate",
          },
          {
            label: "End Time",
            key: "endTimeDate",
          },
          {
            label: "Service/SubCode",
            key: "subCode",
          },
          {
            label: "Authorization #",
            key: "authorizationNumber",
          },
          {
            label: "Hours",
            key: "actualHours",
          },
          {
            label: "Vendor Code",
            key: "vendorCode",
          },
          {
            label: "Details",
            key: "usageNotes",
          },
        ],
      };
    },
    watch: {},
    created() {},
    computed: {
      ...mapGetters(["BillingReport"]),
    },
    mounted() {
      this.$store.dispatch("GetBatchName");
      this.$emit("screenOne", this.screenOne);
    },
    methods: {
      ActualHours(obj) {
        var o = this.BillingReport.hours.find((x) => x.clientId === obj.clientID);
        return o.hours;
      },
      dobformat2(obj) {
        if (obj) {
          var date = new Date(obj);
          return (
            (date.getMonth() > 8
              ? date.getMonth() + 1
              : "0" + (date.getMonth() + 1)) +
            "/" +
            (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
            "/" +
            date.getFullYear()
          );
        }
      },
      ExportPaychex() {
        var records = [];
        this.BillingReport.data.forEach((p) => {
          var count = 0;
          records.forEach((s) => {
            if (new Date(p.startTimeDate).getMonth() == s) {
              count++;
            }
          });
          if (count == 0 && records.length >= 0) {
            records.push(new Date(p.startTimeDate).getMonth());
          }
        });
  
        records = records.sort((a, b) => {
          return a - b;
        });
  
        var filename = "Billing Summary";
        var data = [];
        var header = "";
        var months = "";
        var dates = "";
        records.forEach((x) => {
          if (x == 0) {
            months += "January,";
            dates += "1st-15th  |  16th-31st,";
          } else if (x == 1) {
            months += "February,";
            dates += "1st-15th  |  16th-31st,";
          } else if (x == 2) {
            months += "March,";
            dates += "1st-15th  |  16th-31st,";
          } else if (x == 3) {
            months += "April,";
            dates += "1st-15th  |  16th-31st,";
          } else if (x == 4) {
            months += "May,";
            dates += "1st-15th  |  16th-31st,";
          } else if (x == 5) {
            months += "June,";
            dates += "1st-15th  |  16th-31st,";
          } else if (x == 6) {
            months += "July,";
            dates += "1st-15th  |  16th-31st,";
          } else if (x == 7) {
            months += "August,";
            dates += "1st-15th  |  16th-31st,";
          } else if (x == 8) {
            months += "September,";
            dates += "1st-15th  |  16th-31st,";
          } else if (x == 9) {
            months += "October,";
            dates += "1st-15th  |  16th-31st,";
          } else if (x == 10) {
            months += "November,";
            dates += "1st-15th  |  16th-31st,";
          } else if (x == 11) {
            months += "December,";
            dates += "1st-15th  |  16th-31st,";
          }
        });
  
        data.push(
          `${this.form.payerName},Billing Summary: ${this.dobformat2(
            this.form.dates.startDate
          )} To: ${this.dobformat2(
            this.form.dates.endDate
          )},"","","","","","","","","","",${months}`
        );
        // ${this.TimePeriod[0].endTimeDate.split("T")[0]}
  
        header = `"Last Name","First Name","Status","Initial Date","Vendor","Service","Auth #","Start","End","Hrs","Miles","Details",${dates}`;
  
        data.push(header);
  
        this.BillingReport.data.forEach((x) => {
          var row = `"${x.employeeLastName}","${x.employeeFirstName}","${
            x.payerIsActive ? "Active" : "Inactive"
          }","","${x.vendorCode}","${x.subCode}","${
            x.authorizationNumber
          }"," ${this.dobformat2(x.authorizeStartDate)}",${this.dobformat2(
            x.authorizeendDate
          )},"${x.hours}","0",${x.usageNotes != null ? x.usageNotes : ""}`;
          data.push(row);
        });
  
        // Employee.forEach((E, Ei) => {
        //   this.TimePeriod.forEach((T) => {
        //     if (T.caregiverName == E) {
        //       var row = `"${T.caregiverName}","${T.empID}",`;
        //       Services.forEach((S, i) => {
        //         var hours = T.payRate == S ? T.payableHours.toFixed(2) : 0;
        //         var rate = T.payRate == S ? T.payRate : 0;
        //         var ot = 0;
        //         var otRate = 0;
        //         row += `${hours},${rate},${ot},${otRate},`;
        //       });
        //       row += `0`;
  
        //       data.push(row);
        //     }
        //   });
  
        //   data.push("");
        //   data.push("");
        //   if (Ei < Employee.length - 1) {
        //     data.push(header);
        //   }
        // });
        this.downloadCSVFile(data.join("\n"), filename);
      },
      downloadCSVFile(csv, filename) {
        var csv_file, download_link;
  
        csv_file = new Blob([csv], { type: "text/csv" });
  
        download_link = document.createElement("a");
  
        download_link.download = filename;
  
        download_link.href = window.URL.createObjectURL(csv_file);
  
        download_link.style.display = "none";
  
        document.body.appendChild(download_link);
  
        download_link.click();
      },
  
      openNext(data) {
        this.isFetching = true;
        if ((this.form = true)) {
          this.isFetching = false;
          this.form = data;
          this.$bvModal.show("Action");
        }
      },
  
      payroll() {
        this.$bvModal.show("filter");
        this.$bvModal.hide("Action");
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault();
        // Trigger submit handler
        this.handleSubmit();
      },
    },
  };
  </script>
  <style scoped>
  .titlename {
    float: left !important;
    padding-left: 19px;
  }
  .pageheading {
    padding-top: 20px;
    padding-bottom: 50px;
    height: 50px;
    background-color: #ffffff;
  }
  .text-color {
    color: #2d79bd;
  }
  </style>
  