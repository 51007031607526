<template>
  <div>
    <div>
      <div>
        <span class="text-color"> <b>Payor</b> </span> <br />
        <v-select
          v-model="form.payer"
          :items="PayersList"
          item-text="companyName"
          item-value="id"
          :menu-props="{ maxHeight: '400' }"
          persistent-hint
          @change="onVendorCodeIdChangeAndFetch"
        ></v-select>
      </div>
      <div>
        <span class="text-color"> <b>Vendor Code</b> </span> <br />
        <v-select
          :items="vendorList"
          v-model="form.vendorCode"
          multiple
          item-text="vendorCode"
          item-value="vendorId"
        >
        </v-select>
      </div>
      <div>
        <span class="text-color"> <b>Service Month</b> </span> <br />
        <v-select :items="getMonths" v-model="form.month"> </v-select>
      </div>
    </div>

    <div class="float-right mt-10">
      <span>
        <a @click="RESET" class="btn btn-danger btn-sm">Cancel</a>
      </span>
      <span class="m-4">
        <a @click="Apply" class="btn btn-success btn-sm"
          >Export Report
          <span v-if="loader">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular> </span
        ></a>
      </span>
    </div>
  </div>
</template>
  
  <script>
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  name: "overtimeReport",

  mixins: [validationMixin],
  validations: {
    form: {},
  },
  data() {
    return {
      vendorList: [],
      datesofmonths: [],
      selectedmonthreport: [],
      isRemoving: false,
      form: {
        payer: "",
        vendorCode: "",
        month: "",
      },
      menu: false,
      month: "",
      service: "",
      loader: false,
    };
  },
  computed: {
    ...mapGetters([
      "PayersList",
      "ClientsList",
      "BillingReport",
      "InactivityReport",
      "JFSReport",
      "GetVendorCodeByPayerIds",
      "GetRegionalCenterInvoicings",
    ]),

    getMonths() {
      let month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      var date = new Date();
      var arr = [];
      for (var i = 0; i < 6; i++) {
        if (date.getMonth() - i >= 0) {
          arr.push(month[date.getMonth() - i] + " " + date.getFullYear());
        } else {
          arr.push(
            month[12 + (date.getMonth() - i)] + " " + (date.getFullYear() - 1)
          );
        }
      }

      return arr;
    },
  },

  mounted() {
    this.$store.dispatch("getPayersList");
  },
  methods: {
    onVendorCodeIdChangeAndFetch(item) {
      this.onVendorCodeIdChange(item);
      this.VendorCodebyid(item);
    }, 
    onVendorCodeIdChange(item) {
      this.selectedId = item;
    },
    async VendorCodebyid() {
      try {
        await this.$store.dispatch("GetVendorCodeByPayerId", this.selectedId);
        this.vendorList = this.$store.getters.GetVendorCodeByPayerIds;
      } catch (error) {
        console.error("Error fetching vendor list:", error);
      }
    },
 
    async ExportBillingNew(obj) {
      const vendorCodes = this.GetRegionalCenterInvoicings.output.data
  .filter(x => x.vendorCode)        
  .map(x => x.vendorCode)           
  .filter((value, index, self) => self.indexOf(value) === index) 
  .slice(0, 100).join("_")
      var filename = `${this.PayersList.find(payer => payer.id === this.form.payer).companyName}_${vendorCodes}_${this.excelFormat(this.form.month)}`;
      var data = [];
      var header = "";
      var payerName = this.PayersList.find(payer => payer.id === this.form.payer).companyName;

      data.push(
    `${payerName},Regional Center Invoicing : ${this.dobformat2(
      this.form.month
    )}`
  );
      const days = Array.from({ length: 31 }, (_, i) => `day${i + 1}`);
      const daysString = days.join(",");
      header = `"Last Name","First Name","UCI","Status","Initial Date","Vendor","Service","Auth #","Start","End","Hrs","Shared Services","Details",${daysString}`;
      data.push(header);
      obj.forEach((x) => {
        if (x.payerIsActive != "Terminated") {
          var row = `"${x.lastName}","${x.firstName}","${x.uci}","${
            x.payerIsActive
          }","${this.dobformat2(x.joinDate)}","${x.vendorCode}","${
            x.subCode
          }","${x.authorizationNumber}"," ${this.dobformat2(
            x.start
          )}","${this.dobformat2(x.end)}","${x.hours}","${x.sharedServices}","${
            x.details != null ? x.details.replaceAll(",", " ") : ""
          }",
          "${x.day1 > 0 ? x.day1 : ""}",
          "${x.day2 > 0 ? x.day2 : ""}",
          "${x.day3 > 0 ? x.day3 : ""}",
          "${x.day4 > 0 ? x.day4 : ""}",
          "${x.day5 > 0 ? x.day5 : ""}",
          "${x.day6 > 0 ? x.day6 : ""}",
          "${x.day7 > 0 ? x.day7 : ""}",
          "${x.day8 > 0 ? x.day8 : ""}",
          "${x.day9 > 0 ? x.day9 : ""}",
          "${x.day10 > 0 ? x.day10 : ""}",
          "${x.day11 > 0 ? x.day11 : ""}",
          "${x.day12 > 0 ? x.day12 : ""}",
          "${x.day13 > 0 ? x.day13 : ""}",
          "${x.day14 > 0 ? x.day14 : ""}",
          "${x.day15 > 0 ? x.day15 : ""}",
          "${x.day16 > 0 ? x.day16 : ""}",
          "${x.day17 > 0 ? x.day17 : ""}",
          "${x.day18 > 0 ? x.day18 : ""}",
          "${x.day19 > 0 ? x.day19 : ""}",
          "${x.day20 > 0 ? x.day20 : ""}",
          "${x.day21 > 0 ? x.day21 : ""}",
          "${x.day22 > 0 ? x.day22 : ""}",
          "${x.day23 > 0 ? x.day23 : ""}",
          "${x.day24 > 0 ? x.day24 : ""}",
          "${x.day25 > 0 ? x.day25 : ""}",
          "${x.day26 > 0 ? x.day26 : ""}",
          "${x.day27 > 0 ? x.day27 : ""}",
          "${x.day28 > 0 ? x.day28 : ""}",
          "${x.day29 > 0 ? x.day29 : ""}",
          "${x.day30 > 0 ? x.day30 : ""}",
          "${x.day31 > 0 ? x.day31 : ""}" `;


          data.push(row);
        }
      });

      var data1 = data[0].split(`,""`);
      var data2 = data[1].split(",");

      var reportHeaders = "";
      data1.forEach((e, i) => {
        if (i == 0) {
          reportHeaders += `<th style="border:1px solid black;font-weight:bold;">${
            e.split(",")[0]
          }</th>
            <th colspan="3" style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">${
              e.split(",")[1]
            }</th>`;
        } else if (i == data1.length - 1) {
          e.split(",").forEach((f) => {
            reportHeaders += `<td style="margin:0;text-align:center;border:1px solid black;font-weight:bold;">${f}</td>`;
          });
        } else {
          reportHeaders += `<td style="border:1px solid black;font-weight:bold;">${e}</td>`;
        }
      });
      var Headers = "";
      data2.forEach((e) => {
        Headers += `<th style="border:1px solid black;">${e.replaceAll(
          `"`,
          ""
        )}</th>`;
      });
      var rows = "";
      data.forEach((d, i) => {
        if (i > 1) {
          rows += "<tr>";
          d.split(`","`).forEach((e, j) => {
            if (j == d.split(`","`).length - 1) {
              e.split(`,`).forEach((f) => {
                rows += `<td style="border:1px solid black;">${f.replaceAll(
                  `"`,
                  ""
                )}</td>`;
              });
            } else {
              if (e == "Inactive") {
                rows += `<td style="color:black;    background-color: yellow; border:1px solid black;">${e.replaceAll(
                  `"`,
                  ""
                )}</td>`;
              } else if (e == "Pending Termination") {
                rows += `<td style="color:red;    background-color: yellow; border:1px solid black;">${e.replaceAll(
                  `"`,
                  ""
                )}</td>`;
              } else {
                rows += `<td style="border:1px solid black;">${e.replaceAll(
                  `"`,
                  ""
                )}</td>`;
              }
            }
          });
          rows += "</tr>";
        }
      });

      var test = `
      <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>
      <head>
      <xml>
      <x:ExcelWorkbook>
      <x:ExcelWorksheets>
      <x:ExcelWorksheet>
      <x:Name>Sheet 1</x:Name>
      <x:WorksheetOptions>
      <x:Print>
      <x:ValidPrinterInfo/>
      </x:Print>
      </x:WorksheetOptions>
      </x:ExcelWorksheet>
      </x:ExcelWorksheets>
      </x:ExcelWorkbook>
      </xml>
      </head>
      <body>
      <table>
      <tr>
        ${reportHeaders}
      </tr>
  <tr>
    ${Headers}

  </tr>
  ${rows}
</table>
</body>
</html>`;
      this.downloadCSVFile2(test, filename);
    },
    downloadCSVFile2(csv, filename) {
      var csv_file, download_link;

      csv_file = new Blob([csv], { type: "text/csv" });

      download_link = document.createElement("a");

      download_link.download = filename;
      var base64data = "base64," + btoa(csv);
      //window.open('data:application/vnd.ms-'+defaults.type+';filename=exportData.doc;' + base64data); //this was original

      // Now the little tricky part.(By shreedhar)
      // you can use either>> window.open(uri);
      // but this will not work in some browsers
      // or you will not get the correct file extension
      var uri = "data:application/vnd.ms-excel;" + base64data;

      // download_link.href = window.URL.createObjectURL(csv_file);
      download_link.href = uri;

      download_link.style = "visibility:hidden";

      document.body.appendChild(download_link);

      download_link.click();
    },
    
    dobformat2(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
      return "";
    },
    excelFormat(obj) {
      if (obj) {
        var date = new Date(obj);
        var monthNames = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
        var month = monthNames[date.getMonth()];
        var year = date.getFullYear();
        return `${month}${year}`;
      }
    },
    async Apply() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.loader = true;
        try {
          const vendorCodes = this.vendorList
            .filter((vendor) => this.form.vendorCode.includes(vendor.vendorId))
            .map((vendor) => vendor.vendorCode)
            .join(",");

          const month = this.form.month;

          const response = await this.$store.dispatch(
            "GetRegionalCenterInvoicing",
            {
              payer: this.form.payer,
              vendorCode: vendorCodes,
              month,
            }
          );
          if (response.output.data.length > 0) {
            this.ExportBillingNew(response.output.data);
            this.loader = false;
            this.$bvModal.hide("filter");
          } else {
            this.loader = false;
            Swal.fire({
              title: "",
              text: "No Record Found",
              icon: "error",
              confirmButtonClass: "btn btn-danger",
            });
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          this.loader = false;
        }
      }
    },
    RESET() {
      (this.form.batchName = []),
        (this.form.date = []),
        (this.form.dates = {}),
        this.$bvModal.hide("filter");
    },
  },
};
</script>
  
  <style>
.v-date-picker-table .v-btn.v-btn--active {
  color: #2d79bd !important;
}

.v-btn--outlined {
  border: thin solid #2d79bd !important;
}

.text-color {
  color: #2d79bd;
}

.reportrange-text[data-v-1ebd09d2] {
  background: #fff;
  cursor: pointer;
  padding: 10px 10px;
  border: 1px solid #ccc;
  width: 100%;
  overflow: hidden;
  font-size: 15px;
}

.vue-daterange-picker[data-v-1ebd09d2] {
  display: block;
}

.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}
.v-list-item__action .v-input,
.v-list-item__action .v-input__control,
.v-list-item__action .v-input__slot,
.v-list-item__action .v-input--selection-controls__input {
  margin-right: 10px !important;
}
</style>
  